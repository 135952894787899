import { graphql, Link, useStaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import React from 'react'
import { Row, Col } from 'react-bootstrap'

import { Button } from '../components/Button'
import { Layout } from '../components/Layout'
import { FeaturedNewsSlider } from '../components/FeaturedNewsSlider'
import { NewsFeed } from '../components/NewsFeed'
import { PRStore } from '../components/PRStore'
import { Schedule } from '../components/Schedule'
import { SEO } from '../components/Seo'
import { Section } from '../components/Section'

import * as classes from './home.module.scss'

export default () => {
  const data = useStaticQuery(graphql`
    query Home {
      banner1: wordpressWpMedia(title: { eq: "PR_Banner_Minibasket_2024" }) {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1080, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }

      banner2: wordpressWpMedia(title: { eq: "Banner campagnabb202425" }) {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1080, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }

      document: wordpressWpMedia(title: { eq: "Poster Scuola Basket 2" }) {
        localFile {
          publicURL
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title='Home' />

      <Section className={classes.news} withContainer>
        <Row>
          <Col xs={12} md={7} className={classes.featuredNewsSlider}>
            <FeaturedNewsSlider />
          </Col>

          <Col xs={12} md={5} className={classes.schedule}>
            <Schedule />
          </Col>
        </Row>
      </Section>

      <Section withContainer>
        <Link
          to={
            'https://www.pallacanestroreggiana.it/news/tutte-le-news/campagna-abbonamenti-2024-2025-tutti-i-dettagli'
          }
        >
          <GatsbyImage
            fluid={data.banner2?.localFile?.childImageSharp?.fluid}
          />
        </Link>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '16px',
          }}
        >
          <Button
            as={Link}
            to={
              'https://www.pallacanestroreggiana.it/news/tutte-le-news/campagna-abbonamenti-2024-2025-tutti-i-dettagli'
            }
          >
            Scopri di più
          </Button>
        </div>
      </Section>

      <Section withContainer>
        <Link to={'https://www.pallacanestroreggiana.it/minibasket-2024-2025'}>
          <GatsbyImage
            fluid={data.banner1?.localFile?.childImageSharp?.fluid}
          />
        </Link>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '16px',
          }}
        >
          <Button
            as={Link}
            to={'https://www.pallacanestroreggiana.it/minibasket-2024-2025'}
          >
            Scopri di più
          </Button>
        </div>
      </Section>

      <Section title={'Ultime news'} withContainer>
        <NewsFeed category={'news'} preview />
      </Section>

      <Section title={'PR Store'} className={classes.prStore} withContainer>
        <PRStore />
      </Section>
    </Layout>
  )
}
